<template>

     <div class="mt-0" >
        
          <!-- Modal -->
<div class="modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" >
    <div class="modal-content" :class="{'border_status_green' : stockModal.CMPLAPP, 'border_status_red' : !stockModal.CMPLAPP}">
      <div class="modal-header pl-3 pr-3 pt-2 pb-2">
          <div class="container">
               <div class="row">
                    
                    <div class="col-12 position-relative" style="text-align:center;">
                         <h5 class="modal-title position-absolute" style="left:0;"><b>{{ stockModal.DOCNUM }}</b> - {{ _date(stockModal.DOCDAT) }}</h5>
                         <h5 class="modal-title" id="exampleModalLabel"><b>{{ stockModal.CUSNAM }}</b> </h5>
                         <h5 class="modal-title position-absolute"  :class="{'text-success' : stockModal._MUST_PAID_AMT == stockModal.NETAMT, 'text-danger' : stockModal._MUST_PAID_AMT == stockModal.NETAMT}" style="right:5px;top:0;">
                              <template v-if="stockModal._MUST_PAID_AMT == stockModal.NETAMT">
                                   <span class="bg-success" style="display:block;color:white;padding-left:5px;padding-right:5px;"><b>ชำระสำเร็จ</b></span>
                              </template>
                              <template v-else>
                                   <span class="bg-danger" style="display:block;color:white;padding-left:5px;padding-right:5px;"><b>มียอดคงค้าง</b></span>
                              </template>
                              
                         </h5>
                    </div>
               </div>
          </div>
        
        
      </div>
      <div class="modal-body">
          <div class="row" >

               <div class="col-12">
                    <div style="display:flex;justify-content: center;">

                    <table class="re_table" style="width:100%;">
                         <thead>
                              <tr style="background:#ddd;">
                                   <th>#</th>
                                   <th >เลขที่บิล</th>
                                   <th >ผู้ดูแล</th>
                                   <th>วันที่</th>
                                   <th>ครบกำหนด</th>
                                   <th style="text-align:right;padding-right:10px;">ยอดค้าง</th>
                              </tr>
                              <tr v-for="(item,index) in stockModal._INVOICE_NUM" :key="index">
                                   <td>{{ index+1 }}</td>
                                   <td>
                                        <span  v-if="item.YOUREF != false" :title="item.YOUREF" class="text-warning" style="cursor:pointer;">
                                             {{ item._INVOICE_NUM }}
                                        </span>
                                        <span  v-else >{{ item._INVOICE_NUM }}</span>
                                   </td>
                                   <td >{{ item.OESLM }}</td>
                                   <td>{{ _date(item.DOCDAT) }}</td>
                                   <td>{{ _date(item.DUEDAT) }}</td>
                                   <td style="text-align:right;padding-right:10px;">{{ _number_format(item._INVOICE_AMT) }}</td>
                              </tr>
                              <tr>
                                   <td colspan="5" style="text-align:right;padding-right:20px;border:0px !important;"><b>รวมต้องชำระ</b></td>
                                   <td style="text-align:right;padding-right:10px;"><b>{{ _number_format(parseFloat(stockModal._MUST_PAID_AMT)) }}</b></td>
                              </tr>
                         </thead>
                    </table>
               </div>
               <div class="mt-4" style="display:flex;justify-content: center;">
                    <table class="re_table" style="width:100%;">
                         <thead>
                              <tr style="background:#ddd;">
                                   <th>#</th>
                                   <th>รหัส</th>
                                   <th>วันที่</th>
                                   <th >ธนาคาร</th>
                                   <th style="text-align:right;padding-right:10px;">ยอดชำระ</th>
                              </tr>
                              <tr v-for="(item,index) in stockModal._RE_LIST" :key="index">
                                   <td>{{ index+1 }}</td>
                                   <td>{{ item.CHQNUM }}</td>
                                   <td>{{ _date(item._PAYMENT_DAT) }}</td>
                                   <td >{{ item._PAYMENT_TYPE }}</td>
                                   <td style="text-align:right;padding-right:10px;">{{ _number_format(item.RCVAMT) }}</td>
                              </tr>
                              <tr>
                                   <td colspan="4" style="text-align:right;padding-right:20px;border:0px !important;"><b>รวมยอดชำระ</b></td>
                                   <td style="text-align:right;padding-right:10px;">
                                        <i v-if="stockModal.NETAMT != stockModal._SUM_RE" class="bi bi-exclamation-diamond-fill text-warning"></i> <b>{{ _number_format(parseFloat(stockModal.NETAMT)) }}</b>
                              </td>
                              </tr>
                              <tr v-if="stockModal.NETAMT != stockModal._MUST_PAID_AMT">
                                   <td class="text-danger" colspan="4" style="text-align:right;padding-right:20px;border:0px !important;"><b>ยอดคงค้าง</b></td>
                                   <td class="text-danger" style="text-align:right;padding-right:10px;">
                                        <b>{{ _number_format(parseFloat(stockModal.NETAMT - stockModal._MUST_PAID_AMT)) }}</b>
                              </td>
                              </tr>
                         </thead>
                    </table>
               </div>


               
               </div>


               
          </div>
          
          
        
      </div>
      <div class="modal-footer pl-0 pt-2 pb-2 pr-3 position-relative">
          
          <span v-if="stockModal.re_status && !this.loadingTrigger" class="position-absolute h4 text-success" style="left:15px;font-weight:bold;"><i class="bi bi-check-circle-fill"></i> ตรวจแล้ว <span class="text-secondary">{{ _date(stockModal.last_updated) }}</span></span>
          <span v-if="this.loadingTrigger" class="position-absolute h4 text-warning" style="left:15px;font-weight:bold;">
               <div class="position-relative"> 
               <div class="spinner-border spinner-border-sm p-0 position-absolute" role="status" style="left:0;top:6px">
               </div></div> <span style="padding-left:23px;">กำลังโหลด...</span>
          </span>
        <button id="close" type="button" class="btn btn-secondary" data-bs-dismiss="modal">ปิด</button>
        <button v-if="lastIndex != 0" type="button" class="btn btn-secondary" @click="_previous(lastIndex);">ก่อนหน้า</button>
        <button v-if="!stockModal.re_status" type="button" class="btn btn-primary" @click="_save_and_next(lastIndex);">ตรวจแล้ว ถัดไป</button>
        <button v-else type="button" class="btn btn-primary" @click="_openModal(lastIndex+1)">ถัดไป</button>
        
        <button v-if="!stockModal.re_status" type="button" class="btn btn-secondary" @click.prevent="_save_and_close(lastIndex);">
         
          ตรวจแล้ว
     </button>
        <button v-else type="button" class="btn btn-warning" @click="_save(lastIndex);">
          ยกเลิกการตรวจ
     </button>
      </div>
    </div>
  </div>
</div>

          <div class="container mb-0 pl-2 pr-2  mt-1" style="border-top:2px solid white;">
               <div class="row">
                    <div class="col-12" >
                         <table class="table table-sm table-bordered mb-1 mt-2 month_menu">
                              <tbody>
                                   <tr v-for="year,index in _year" :key="index">
                                        <td :month=-1 :year=-1 v-if="index==0" @click="_activate(-1,-1);_applyFilter(-1,-1)" :class="{ 'active' : active_el == -1 && active_el2==-1}"  :rowspan="_year.length" style="text-align:center;vertical-align:middle"><b>ทั้งหมด</b></td>
                                        <td month="''" :year="year" @click="_activate('00',year);_applyFilter('00',year)" :class="{ 'active' : active_el == '00' && active_el2==year}"><b>{{ year }}</b></td>
                                        <td v-for="m,index2 in months" :key="index2" :month="m" :year="year" @click="_activate(m,year);_applyFilter(m,year)" :class="{ 'active' : active_el == m && active_el2 == year }" class="position-relative">
                                             {{months_name[index2]}} 
                                             <span v-if="_count(m,year) > 0" class="position-absolute top-0 start-90 translate-middle badge rounded-pill bg-danger" style="padding:3px;min-width:18px;" >{{_count(m,year)}}</span>
                                             <span v-show="false" class="position-absolute translate-middle badge  bg-secondary" style="font-size:0.5em;right:-7px;top:7px">{{ _count(m,year) }}
                                                  <span class="visually-hidden">unread messages</span>
                                             </span>
                                        </td>
                                   </tr>
                              </tbody>
                         </table>
                    </div>
                         
               </div>

              
               
               
          </div>
     
     <div class="container" >
          <div class="row">
               <div class="col-12">
               <dataset
               v-slot="{ ds }"
               :ds-data="stocks"
               :ds-sortby="sortBy"
               :ds-filter-fields="{ 
                                        _MONTH: filters.monthFilter,
                                        _YEAR: filters.yearFilter
                                        }"
               :ds-search-in="['DOCNUM','CUSNAM', 'NETAMT']"
               >
               
               <div class="row mb-2">
                    
                    <div class="col-6 mt-2" style="text-align:left;" >
                         <div style="display:inline;">
                              <h5 class="mb-0"  :class="{'text-secondary' : dept_type==0, 'text-danger' : dept_type == 1, 'text-dark' : dept_type==2, 'text-success' : dept_type==3}" style="font-weight:bold;">รวมทั้งหมด {{ _number_format(this._sum) }} บาท</h5></div>
                    </div>
                    <div class="col-6 mt-2" style="text-align:right;" >
                         <dataset-info class="mb-md-0" style="padding-left:10px;font-size:0.9em;"/>
                    </div>
               </div>
               <div class="row mb-0 " :data-page-count="ds.dsPagecount" >
                    <div class="col-6 pl-1 mb-0" style="text-align:left">
                         <dataset-show v-show="false" :ds-show-entries="50" />
                         <dataset-search style="width:300px;" ds-search-placeholder="ค้นหา..." />
                    </div>
                    <div class="col-6 mb-0" style="text-align:right">
                         <dataset-pager style="float:right;" />
                    </div>
               </div>
              
               <div class="row" style="background:#eee;">
                    <div class="col-md-12">
                    <div class="table-responsive">
                         <table class="table table-striped d-md-table">
                         <thead>
                         <tr>
                              <th scope="col">#</th>
                              <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]" :style="th.style" @click="click($event, index)">
                              {{ th.name }} <i class="gg-select float-right"></i>
                              </th>
                         </tr>
                         </thead>
                         <dataset-item tag="tbody">
                         <template #default="{ row, rowIndex, index }">
                              <tr class="tr_border" :class="{'last_active' : rowIndex == lastIndex, 're_status' : row.re_status == 1,'dept_left' : row._DEPT_LEFT != 0}" data-bs-toggle="modal" data-bs-target="#exampleModal" @click="_openModal(rowIndex)">
                              <th scope="row">{{ index+1 }} <span v-show="false">{{ rowIndex }}</span></th>
                              <td >{{ row.DOCNUM }} </td>
                              <td>{{ row._LENGTH }} </td>
                              <td>{{ _date(row.DOCDAT)  }}</td>
                              <td style="text-align:left">{{ row.CUSNAM }}</td>
                              <td style="text-align:right">{{ _number_format(row._MUST_PAID_AMT) }}</td>
                              <td style="text-align:right">{{ _number_format(row._DEPT_LEFT) }}</td>
                              
                              </tr>
                         </template>
                         </dataset-item>
                         </table>
                    </div>
                    </div>
               </div>
               <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-info class="mb-2 mb-md-0" />
                    <dataset-pager />
               </div>
               </dataset>
          </div>
          </div>
     </div>

     </div>
   </template>

<script>
import moment from 'moment'

import { 
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from 'vue-dataset'
import StockService from "./StockService.js"

export default {
  name: 'HelloWorld',
  components: { Dataset,DatasetItem,DatasetInfo,DatasetPager,DatasetSearch,DatasetShow },

  data: function () {
    return {
     lastIndex : null,
     stockModal : {},
     loadingTrigger : false,
      showMobileMenu: false,
      stocks: [],
      loadingClass:0,
      fileName:"Test",
      active_el:-1,
      active_el2:-1,
      dept_type:0,
      month_:-1,
      year_:-1,
      months:['01','02','03','04','05','06','07','08','09','10','11','12'],
      months_name:['มค.','กพ.','มีค.','เม.ย.','พค.','มิย.','กค.','สค.','กย.','ตค.','พย.','ธค.'],
      filters :{ monthFilter : '', yearFilter : '', dept_leftFilter : '', overdueFilter : ''},
      cols: [
      {
          name: 'เลขที่บิล',
          field: 'DOCNUM',
          sort: ''
        },
        {
          name: '#บิล',
          field: '_LENGTH',
          sort: ''
        },
        {
          name: 'วันที่',
          field: 'DOCDAT',
          sort: ''
        },
        
        {
          name: 'ชื่อลูกค้า',
          field: 'CUSNAM',
          sort: ''
        },
        {
          name: 'ยอดทั้งหมด',
          field: '_MUST_PAID_AMT',
          sort: '',
          style: {
          'text-align': 'right'
          }
        },
        {
          name: 'หนี้คงเหลือ',
          field: '_DEPT_LEFT',
          sort: '',
          style: {
          'text-align': 'right'
          }
        },
        
        {
          name: 'เดือน',
          field: '_MONTH',
          sort: '',
          style: {
          'display': 'none'
          }
        },
        {
          name: 'ปี',
          field: '_YEAR',
          sort: '',
          style: {
          'display': 'none'
          }
        },

       
      ]
    }
  },
  async created() {
          moment.locale("th")
          this.fileName = "โหลดโปรแกรม"
          let redis_check = await StockService.getRe();
          if(redis_check){
               this.stocks = redis_check;
          }else console.log("Cannot Get RE from Web Server")

          
     },
  computed: {
     _sum(){
         
          if((this.month_ == -1)&& (this.year_==-1)){
               let sum = 0;
               this.stocks.forEach(item => {
                    if(this.dept_type == 1){
                         if(item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 2){
                         if(item._DEPT_LEFT && item._OVERDUE)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 3){
                         if(!item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else{
                         sum += item.NETAMT;
                    }
               });
               return sum;
          }else if(this.month_ == '00'){
               let sum = 0;
               this.stocks.forEach(item => {
               if(item._YEAR == this.year_)
               if(this.dept_type == 1){
                         if(item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 2){
                         if(item._DEPT_LEFT && item._OVERDUE)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 3){
                         if(!item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else{
                         sum += item.NETAMT;
                    }
               });
               return sum;
          }else{
               let sum = 0;
               this.stocks.forEach(item => {
               if((item._YEAR == this.year_) && (item._MONTH == this.month_))
               if(this.dept_type == 1){
                         if(item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 2){
                         if(item._DEPT_LEFT && item._OVERDUE)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 3){
                         if(!item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else{
                         sum += item.NETAMT;
                    }
               });
               return sum;
          }
     },
     _year(){
          let year = []
          this.stocks.map(e=>{
               (!year.includes(e._YEAR)) && (year.push(e._YEAR))
          })
               return year;
     },
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === 'asc' ? acc.push(o.field) : acc.push('-' + o.field)
        }

        return acc
      }, [])
    }
  },
  mounted() {
          
          //this.stockModal = this.stocks[this.lastIndex];
          // รับว่า LoadingClass เป็นเท่าไหร่แล้ว
          //this.$socket.on('connect', () => { console.log('Socket Connected') });
          //this.$socket.on('disconnect', () => { console.log('Socket Disconnected') });
          //this.$socket.on('loadingClass', (res)=>{ this.loadingClass = res;  });
          //this.$socket.on('fileName',(res)=>{ this.fileName = res; console.log(res) });
          this.$socket.on('refresh2', async () => { 
               let redis_check = await StockService.getRe();
          if(redis_check){
               this.stocks = redis_check;
               (this.lastIndex) && (this.stockModal = this.stocks[this.lastIndex])
          }else console.log("peter")
           });

           this.$socket.on('refreshSoft2', async (result)=>{ 
               if(result){
                    Object.assign(this.stocks[result.index], result.data);
                    (this.lastIndex) && (this.stockModal = this.stocks[this.lastIndex]);
               }
          });
    },
  methods: {
     _save(index){
          this.saveReConfig(index);
     },
     async _previous(index){
          this.loadingTrigger = true;
          index = (index == null) ? 0 : index;
          index = (index == 0) ? 0 : index-1;
          this._openModal(index);
          this.loadingTrigger = false;
     },
     async _save_and_next(index){
          this.loadingTrigger = true;
          await this.saveReConfig(index);
          this.loadingTrigger = false;
          this._openModal(index+1);
     },
     async _save_and_close(index){
          this.loadingTrigger = true;
          await this.saveReConfig(index);
          this.loadingTrigger = false;
          document.getElementById('close').click();
     },

     async saveReConfig(index){
          this.loadingTrigger = true;
          let params = {
                    "DOCNUM" : this.stockModal.DOCNUM,
                    "re_status" : ((this.stockModal.re_status == null) ||  (this.stockModal.re_status == 0))? 1 : 0,
                    "last_updated" : moment().format('YYYY-MM-DD HH:mm:ss')
          }
          try{ 
                   let result = await StockService.saveReConfig(index,params);
                   (result) && Object.assign(this.stocks[index], result) && this.$socket.emit('refreshSoft2',{"index":index,"data":result});
                   this.loadingTrigger = false;
               }catch(err){ console.log("Save _products_config Error",err) }
     },
     _closeModal(){
          document.getElementById('close').click();
     },
     _openModal(rowIndex){
          this.lastIndex = rowIndex;
          this.stockModal = this.stocks[rowIndex];
     },
     _dept_notify_all(type){
          if(type ==0)
          return this.stocks.length;
          else if(type ==1)
          return this.stocks.filter(e =>e._DEPT_LEFT).length;
          else if(type ==2)
          return this.stocks.filter(e =>e._OVERDUE && e._DEPT_LEFT).length;
          else if(type ==3)
          return this.stocks.filter(e => !e._DEPT_LEFT).length;
          else return 0
     },
     _dept_notify(month,year){
          if(this.dept_type == 0)
          return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() )).length;
          else if(this.dept_type ==1)
          return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() && e._DEPT_LEFT )).length;
          else if(this.dept_type ==2)
          return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() && e._DEPT_LEFT && e._OVERDUE )).length;
          else if(this.dept_type ==3)
          return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() && !e._DEPT_LEFT )).length;
          else return 0
     },
     _class(e){
          
          let a = document.getElementsByClassName("deptt");
          for(let i=0 ; i<a.length ; i++){
               a[i].classList.remove("active")
          }
          e.target.classList.add('active')
          

     },
     _count(month,year){
         return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() && e.re_status == 0 )).length;
     },
     _applyFilter(month,year){
          this.month_ = month;
          this.year_ = year;
          this.filters.monthFilter = month;
          (month == '00' || month == -1) && (this.filters.monthFilter = '');
          this.filters.yearFilter = year;
          (year == -1) && (this.filters.yearFilter = '');
     },
     
     _change_dept_type(depType){
          this.dept_type = depType
     },
     _activate:function(month,year){
        this.active_el = month;
        this.active_el2 = year;
    },
     _toggle_menu(){
          this.$el.querySelectorAll('.month_menu');
     },
     _date(date){
          return ((date == null) || (date == false)) ? 'ไม่ระบุวันที่' : moment(date).add(543, 'year').format("L");
     },
     _number_format(num){ 
          return num.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
     },
    click(event, i) {
      let toset
      const sortEl = this.cols[i]

      if (!event.shiftKey) {
        this.cols.forEach((o) => {
          if (o.field !== sortEl.field) {
            o.sort = ''
          }
        })
      }
      if (!sortEl.sort) {
        toset = 'asc'
      }
      if (sortEl.sort === 'desc') {
        toset = event.shiftKey ? '' : 'asc'
      }
      if (sortEl.sort === 'asc') {
        toset = 'desc'
      }
      sortEl.sort = toset
    }
  }
}



  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td.active{
     background:#DC3545 !important;
     color:white;
     font-weight:bold !important;
}
table.month_menu td:hover{
     cursor:pointer;
}
ul.dept_menu li:hover{
     cursor:pointer;
}
ul.dept_menu li.active{
     font-weight:bold;
     color:white;
     background:#DC3545;
     padding:3px;
}
.hidden{
     display:none !important;
}
.loading{
     display:none;
}
tr.tr_border:hover{
     -webkit-box-shadow:inset 0px 0px 0px 2px #888;
    -moz-box-shadow:inset 0px 0px 0px 2px #888;
    box-shadow:inset 0px 0px 0px 2px #888;
     cursor:pointer;
}
table.re_table td{
     border:1px solid #ddd;
}
tr.last_active{
     font-weight:bold;
}
.modal-content{
     border-radius: 0 !important;
    
}
.border_status_green{
     border:5px solid green !important;
}
.border_status_red{
     border:5px solid #DC3545 !important;
}
tr.re_status{
     background:#E5FFCC !important;
}
tr.dept_left{
     text-decoration:underline;
     font-style:italic;
}

</style>
