<template>
  <div class="home container-fulid">
     <div class="row">
          <div class="col"></div>
          <div class="fixed position-relative">
               <div  class="position-absolute" style="right:15px;top:-25px;">
                    <span style="color:#555;font-size:0.9em;">
                         <img v-if="isLoading"  src="../assets/loading.gif" style="width:25px;float:right;margin-left:5px;"/>
                         {{ fileName }} 
                    </span>
               </div>
               <ul class="nav nav-pills mb-0" id="pills-tab" role="tablist" style="background:#eee">
                    <li class="nav-item" role="presentation">
                    <button class="nav-link  active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" style="position:relative;padding:3px;padding-left:8px;padding-right:8px;border-radius: 0;">เรียงตามเลขที่บิล</button>
                    </li>
                    <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false" style="position:relative;padding:3px;padding-left:8px;padding-right:8px;border-radius: 0;">เรียงตามเลขที่รับชำระ</button>
                    </li>
               </ul>
               <div class="tab-content" id="pills-tabContent">
               <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <HelloWorld msg="Welcome to Your Vue.js App"/>
               </div>
               <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">...</div>
               <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                    <HelloWorld2 msg="Welcome to Your Vue.js App"/>
               </div>
               </div>
               
          </div>
    <div class="col"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import HelloWorld2 from '@/components/HelloWorld2.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,HelloWorld2
  },
  data: function () {
    return {
      fileName:"Test",
      isLoading : false,
      loadingClass: 0
    }
  },
  async created() {
          this.fileName = "โหลดโปรแกรม"
          this.$socket.emit('checkIsloading');
          this.$socket.emit('checkFilename');
     },
     mounted() {
          
          //this.stockModal = this.stocks[this.lastIndex];
          // รับว่า LoadingClass เป็นเท่าไหร่แล้ว
          this.$socket.on('connect', () => { console.log('Socket Connected') });
          this.$socket.on('disconnect', () => { console.log('Socket Disconnected'); });

          this.$socket.on('isLoading', (res) => { this.isLoading = res  });
          this.$socket.on('fileName2',(res)=>{ this.fileName = res; console.log(this.fileName)});

          
          
         
          
    },
    
}
</script>
<style scoped>
.nav-link{
     color:#777;
}
.nav-link:hover {
  font-weight:bold;
  color:#555;
}
.nav-link.active{
     background:#DC3545 !important;
     font-weight: bold;
}
.nav-link.active:hover{
     color:white !important;
}
.fixed{
    width: 960px;
}
</style>
