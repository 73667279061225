import axios from "axios";
//const url = "http://localhost:5000/api/stocks";
//const url = "https://stocks-api-337015.de.r.appspot.com/api/stocks";
//const url = (process.env.NODE_ENV == "development") ? "http://192.168.1.170:5000/api/stocks" : "https://stocks-api-337015.de.r.appspot.com/api/stocks";
//const url = "https://web-server-real.vercel.app/api/stocks";
const url = "https://client-web-api.thaisugars.com/api/stocks";
 



class StockService  {


// Refresh Stocks
static refreshStocks(){
     return new Promise((resolve,reject) => {
         axios.get(url+'/refresh')
         .then(function (response) {
             const data = response.data;
             resolve(data);
         })
         .catch(function (error) {
           // handle error
           reject(error); 
         })
     })
 }



static getInvoice(){
     return new Promise((resolve,reject) => {
         axios.get(url+'/getInvoice')
         .then(function (response) {
             const data = response.data;
             resolve(data);
         })
         .catch(function (error) {
           // handle error
           reject(error); 
         })
     })
 }
 static getRe(){
     return new Promise((resolve,reject) => {
         axios.get(url+'/getRe')
         .then(function (response) {
             const data = response.data;
             resolve(data);
         })
         .catch(function (error) {
           // handle error
           reject(error); 
         })
     })
 }
    // Refresh Stocks
    static refreshStocks2(){
        return new Promise((resolve,reject) => {
            axios.get(url+'/refresh2')
            .then(function (response) {
                const data = response.data;
                resolve(data);
            })
            .catch(function (error) {
              // handle error
              reject(error); 
            })
        })
    }


    // เพิ่ม หรือ แก้ไข _products_config
    static saveReConfig(index,data){
     return new Promise((resolve,reject) => {
         axios.post(url+'/update_re_config',{"index":index,"data":data})
         .then(function (response) {
             resolve(response.data);
         })
         .catch(function (error) {
           // handle error
           reject(error);
         })
     })
 }

}

export default StockService