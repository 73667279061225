<template>
     <nav class="navbar navbar-expand-lg navbar-light bg-light mb-4">
  <div class="container-fluid">
    <a class="navbar-brand h1 mb-0" href="#" style="font-size:1.5em;"><b>เซียวบุ่นเส่ง</b></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#" style="padding-left:0"><router-link to="/about"><i class="bi bi-buildings"></i> ผลประกอบการ</router-link></a>
        </li>
        <li class="nav-item">
          <a class="nav-link " aria-current="page" href="#"><router-link to="/"><i class="bi bi-layout-text-sidebar" ></i> ลูกหนี้</router-link></a>
        </li>
        <li class="nav-item">
          <a class="nav-link " aria-current="page" href="#"><router-link to="/about2"><i class="bi bi-person-video2"></i> เจ้าหนี้</router-link></a>
        </li>
        <li class="nav-item">
          <a class="nav-link " aria-current="page" href="#"><router-link to="/stocks"><i class="bi bi-box"></i> คลังสินค้า</router-link></a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
               <i class="bi bi-file-earmark-bar-graph-fill"></i> รายงาน
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">พิมพ์ใบปะหน้า</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li>
      </ul>
      
    </div>
  </div>
</nav>

  <router-view/>
</template>

<style>
@font-face {
    font-family: 'SukhumvitSet';
    font-style: normal;
    font-weight: normal;
    src: url(./assets/fonts/SukhumvitSet-Text.ttf) format('truetype');
}

@font-face {
    font-family: 'SukhumvitSet';
    font-style: normal;
    font-weight: bold;
    src: url(./assets/fonts/SukhumvitSet-Bold.ttf) format('truetype');
}
#app {
     font-family: SukhumvitSet,Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  color: #2c3e50;
  text-decoration: none;
  
}
nav a:hover {
  font-weight:bold;
  color:black;
}
nav a.router-link-exact-active {
  font-weight: bold;
  color:#DC3545;
}

.nav-link i{
     opacity: 0.4;
}
nav a.router-link-exact-active i{
  opacity: 1;
}
nav a:hover i{
     opacity: 0.8;
}
</style>
