
import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import App from './App.vue'


import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap-icons/font/bootstrap-icons.css'
import router from './router'

import VueSocketio from 'vue-3-socket.io'
import io from 'socket.io-client';


//const url = "http://localhost:8080/"
//const url = "https://notify-server-337209.de.r.appspot.com/"
//const url = (process.env.NODE_ENV == "development") ? "http://localhost:8080/" : "https://notify-server-337209.de.r.appspot.com/";
const url = "https://notify-server-739126534a75.herokuapp.com/"
// const url = "https://notification-server.thaisugars.com/"



const app = createApp(App);
let ioo = io(url,{maxHttpBufferSize: 1e8});
app.config.globalProperties.$socket = ioo;
app.use(router,VueSocketio, ioo).mount('#app')






