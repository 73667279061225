<template>
     <div class="mt-0" >
          <div class="container mb-0 pl-2 pr-2  mt-0" style="border-top:2px solid white;">
               <div class="row">

                    <div class="col-12 mb-2" style="text-align:left;padding-left:0px;background:#eee;padding:0;">
                         <ul  class="dept_menu" style="list-style-type: circle !important;margin:0;">
                              <li class="deptt position-relative active" style="margin-left:0;" @click="
                              this._class($event);
                              this.filters.dept_leftFilter=true;
                              this.filters.overdueFilter='';
                              this._change_dept_type(1) ">ลูกหนี้ค้างชำระทั้งหมด
                                   <span v-if="_dept_notify_all(1) >0" class="position-absolute top-0 start-80 translate-middle badge rounded-pill bg-danger" style="padding:3px;">{{_dept_notify_all(1)}}</span>
                              </li>
                              <li class="deptt position-relative" @click="
                                   _class($event);
                                   this.filters.dept_leftFilter=true;
                                   this.filters.overdueFilter=true;
                                   this._change_dept_type(2)
                              ">เฉพาะลูกหนี้ค้างชำระเกินกำหนด
                                   <span v-if="_dept_notify_all(2) >0" class="position-absolute top-0 start-80 translate-middle badge rounded-pill bg-dark" style="padding:3px;">{{_dept_notify_all(2)}}</span>
                              </li>
                         </ul>
                    </div>

                         
                    <div class="col-12" >
                         <table class="table table-sm table-bordered mb-1 mt-2 month_menu">
                              <tbody>
                                   <tr v-for="year,index in _year" :key="index">
                                        <td :month=-1 :year=-1 v-if="index==0" @click="_activate(-1,-1);_applyFilter(-1,-1)" :class="{ 'active' : active_el == -1 && active_el2==-1}"  :rowspan="_year.length" style="text-align:center;vertical-align:middle"><b>ทั้งหมด</b></td>
                                        <td month="''" :year="year" @click="_activate('00',year);_applyFilter('00',year)" :class="{ 'active' : active_el == '00' && active_el2==year}"><b>{{ year }}</b></td>
                                        <td v-for="m,index2 in months" :key="index2" :month="m" :year="year" @click="_activate(m,year);_applyFilter(m,year)" :class="{ 'active' : active_el == m && active_el2 == year }" class="position-relative">
                                             {{months_name[index2]}} 
                                             <span v-if="_dept_notify(m,year) >0" class="position-absolute top-0 start-90 translate-middle badge rounded-pill " style="padding:3px;min-width:18px;" :class="{'bg-secondary' : dept_type==0, 'bg-danger' : dept_type == 1, 'bg-dark' : dept_type==2, 'bg-success' : dept_type==3}">{{_dept_notify(m,year)}}</span>
                                             <span v-show="false" class="position-absolute translate-middle badge  bg-secondary" style="font-size:0.5em;right:-7px;top:7px">{{ _count(m,year) }}
                                                  <span class="visually-hidden">unread messages</span>
                                             </span>
                                        </td>
                                   </tr>
                              </tbody>
                         </table>
                    </div>
                    
                    

                         
               </div>

              
               
               
          </div>
     
          
     <div class="container" >
          <div class="row">
               
               <div class="col-12">
               <dataset
               v-slot="{ ds }"
               :ds-data="stocks"
               :ds-sortby="sortBy"
               :ds-filter-fields="{ 
                                        _MONTH: filters.monthFilter,
                                        _YEAR: filters.yearFilter,
                                        _DEPT_LEFT: filters.dept_leftFilter,
                                        _OVERDUE: filters.overdueFilter
                                        }"
               :ds-search-in="['DOCNUM','RCPNUM', 'CUSNAM', 'NETAMT']"
               >
               
               <div class="row">
                    
                    <div class="col-6 mt-2" style="text-align:left;" >
                         <div style="display:inline;">
                              <h5 class="mb-0"  :class="{'text-secondary' : dept_type==0, 'text-danger' : dept_type == 1, 'text-dark' : dept_type==2, 'text-success' : dept_type==3}" style="font-weight:bold;">รวมทั้งหมด {{ _number_format(this._sum) }} บาท</h5></div>
                    </div>
                    <div class="col-6 mt-2" style="text-align:right;" >
                         <dataset-info class="mb-2 mb-md-0" style="padding-left:10px;font-size:0.9em;"/>
                    </div>
               </div>

               <div class="row mb-0" :data-page-count="ds.dsPagecount" style="margin-top:10px;">
                    <div class="col-6 pl-1" style="text-align:left">
                         <dataset-show v-show="false" :ds-show-entries="50" />
                         <dataset-search style="width:300px;" ds-search-placeholder="ค้นหา..." />
                         
                    </div>
                    <div class="col-6" style="text-align:right">
                         <dataset-pager style="float:right;" />
                    </div>
               </div>
              
               <div class="row" style="background:#eee;">
                    <div class="col-md-12">
                    <div class="table-responsive">
                         <table class="table table-striped d-md-table">
                         <thead>
                         <tr>
                              <th scope="col">#</th>
                              <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]" :style="th.style" @click="click($event, index)">
                              {{ th.name }} <i class="gg-select float-right"></i>
                              </th>
                         </tr>
                         </thead>
                         <dataset-item tag="tbody">
                         <template #default="{ row, rowIndex, index }">
                              <tr >
                              <th scope="row">{{ index+1 }} <span v-show="false">{{ rowIndex }}</span></th>
                              <td>{{ row.DOCNUM }} </td>
                              <td>{{row.RCPNUM }}</td>
                              <td>{{ _date(row.DOCDAT)  }}</td>
                              <td style="text-align:left">{{ row.CUSNAM }}</td>
                              <td style="text-align:right">{{ _number_format(row.NETAMT) }}</td>
                              <td style="text-align:right">{{ _number_format(row._DEPT_LEFT_AMT) }}</td>
                              <td>{{(row.CMPLAPP == 'N') ? row._DEPT_AGE+' วัน' : 'ชำระแล้ว' }} </td>
                              </tr>
                         </template>
                         </dataset-item>
                         </table>
                    </div>
                    </div>
               </div>
               <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-info class="mb-2 mb-md-0" />
                    <dataset-pager />
               </div>
               </dataset>
          </div>
          </div>
     </div>

     </div>
   </template>

<script>
import moment from 'moment'

import { 
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from 'vue-dataset'
import StockService from "./StockService.js"

export default {
  name: 'HelloWorld',
  components: { Dataset,DatasetItem,DatasetInfo,DatasetPager,DatasetSearch,DatasetShow },

  data: function () {
    return {
     loadingTrigger : false,
      showMobileMenu: false,
      stocks: [],
      loadingClass:0,
      fileName:"Test",
      active_el:-1,
      active_el2:-1,
      dept_type:1,
      month_:-1,
      year_:-1,
      months:['01','02','03','04','05','06','07','08','09','10','11','12'],
      months_name:['มค.','กพ.','มีค.','เม.ย.','พค.','มิย.','กค.','สค.','กย.','ตค.','พย.','ธค.'],
      filters :{ monthFilter : '', yearFilter : '', dept_leftFilter : '', overdueFilter : ''},
      cols: [
      {
          name: 'เลขที่บิล',
          field: 'DOCNUM',
          sort: ''
        },
      {
          name: 'เลขที่ชำระหนี้',
          field: 'RCPNUM',
          sort: ''
        },
        {
          name: 'วันที่',
          field: 'DOCDAT',
          sort: ''
        },
        {
          name: 'ชื่อลูกค้า',
          field: 'CUSNAM',
          sort: ''
        },
        {
          name: 'จำนวนเงิน',
          field: 'NETAMT',
          sort: '',
          style: {
          'text-align': 'right'
          }
        },
        {
          name: 'หนี้คงเหลือ',
          field: '_DEPT_LEFT_AMT',
          sort: '',
          style: {
          'text-align': 'right'
          }
        },
        {
          name: 'ภาษี',
          field: 'VATAMT',
          sort: '',
          style: {
          'text-align': 'right',
          'display' : 'none'
          }
        },
        {
          name: 'เดือน',
          field: '_MONTH',
          sort: '',
          style: {
          'display': 'none'
          }
        },
        {
          name: 'ปี',
          field: '_YEAR',
          sort: '',
          style: {
          'display': 'none'
          }
        },
        {
          name: 'เกินกำหนด',
          field: '_OVERDUE',
          sort: '',
          style: {
          'display': 'none'
          }
        },
        {
          name: 'อายุหนี้',
          field: '_DEPT_AGE',
          sort: ''
        }
       
      ]
    }
  },
  async created() {
          moment.locale("th")
          this.fileName = "โหลดโปรแกรม"
          let redis_check = await StockService.getInvoice();
          if(redis_check){
               this.stocks = redis_check;
          }else console.log("Cannot get Invoice from Web Server")

          
     },
  computed: {
     _sum(){
         
          if((this.month_ == -1)&& (this.year_==-1)){
               let sum = 0;
               this.stocks.forEach(item => {
                    if(this.dept_type == 1){
                         if(item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 2){
                         if(item._DEPT_LEFT && item._OVERDUE)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 3){
                         if(!item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else{
                         sum += item.NETAMT;
                    }
               });
               return sum;
          }else if(this.month_ == '00'){
               let sum = 0;
               this.stocks.forEach(item => {
               if(item._YEAR == this.year_)
               if(this.dept_type == 1){
                         if(item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 2){
                         if(item._DEPT_LEFT && item._OVERDUE)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 3){
                         if(!item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else{
                         sum += item.NETAMT;
                    }
               });
               return sum;
          }else{
               let sum = 0;
               this.stocks.forEach(item => {
               if((item._YEAR == this.year_) && (item._MONTH == this.month_))
               if(this.dept_type == 1){
                         if(item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 2){
                         if(item._DEPT_LEFT && item._OVERDUE)
                         sum += item.NETAMT;
                    }else if(this.dept_type == 3){
                         if(!item._DEPT_LEFT)
                         sum += item.NETAMT;
                    }else{
                         sum += item.NETAMT;
                    }
               });
               return sum;
          }
     },
     _year(){
          let year = []
          this.stocks.map(e=>{
               (!year.includes(e._YEAR)) && (year.push(e._YEAR))
          })
               return year;
     },
    sortBy() {
      return this.cols.reduce((acc, o) => {
        if (o.sort) {
          o.sort === 'asc' ? acc.push(o.field) : acc.push('-' + o.field)
        }

        return acc
      }, [])
    }
  },
  mounted() {
         // รับว่า LoadingClass เป็นเท่าไหร่แล้ว
         // this.$socket.on('loadingClass', (res)=>{ this.loadingClass = res;  });
         // this.$socket.on('fileName',(res)=>{ this.fileName = res; console.log(res) });
         // console.log(this.loadingClass)
         this.$socket.on('refresh2', async () => { 
               let redis_check = await StockService.getInvoice();
               if(redis_check){
                    this.stocks = redis_check;
               }else console.log("peter")
           });
    },
  methods: {
     _dept_notify_all(type){
          if(type ==0)
          return this.stocks.length;
          else if(type ==1)
          return this.stocks.filter(e =>e._DEPT_LEFT).length;
          else if(type ==2)
          return this.stocks.filter(e =>e._OVERDUE && e._DEPT_LEFT).length;
          else if(type ==3)
          return this.stocks.filter(e => !e._DEPT_LEFT).length;
          else return 0
     },
     _dept_notify(month,year){
          if(this.dept_type == 0)
          return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() )).length;
          else if(this.dept_type ==1)
          return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() && e._DEPT_LEFT )).length;
          else if(this.dept_type ==2)
          return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() && e._DEPT_LEFT && e._OVERDUE )).length;
          else if(this.dept_type ==3)
          return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() && !e._DEPT_LEFT )).length;
          else return 0
     },
     _class(e){
          
          let a = document.getElementsByClassName("deptt");
          for(let i=0 ; i<a.length ; i++){
               a[i].classList.remove("active")
          }
          e.target.classList.add('active')
          

     },
     _count(month,year){
         return this.stocks.filter(e => (e._YEAR == year.toString() && e._MONTH == month.toString() )).length;
     },
     _applyFilter(month,year){
          this.month_ = month;
          this.year_ = year;
          this.filters.monthFilter = month;
          (month == '00' || month == -1) && (this.filters.monthFilter = '');
          this.filters.yearFilter = year;
          (year == -1) && (this.filters.yearFilter = '');
     },
     
     _change_dept_type(depType){
          this.dept_type = depType
     },
     _activate:function(month,year){
        this.active_el = month;
        this.active_el2 = year;
    },
     _toggle_menu(){
          this.$el.querySelectorAll('.month_menu');
     },
     _date(date){
          return moment(date).add(543, 'year').format("L");
     },
     _number_format(num){ 
          return num.toLocaleString('en-US', {minimumFractionDigits:2, maximumFractionDigits:2});
     },
    click(event, i) {
      let toset
      const sortEl = this.cols[i]

      if (!event.shiftKey) {
        this.cols.forEach((o) => {
          if (o.field !== sortEl.field) {
            o.sort = ''
          }
        })
      }
      if (!sortEl.sort) {
        toset = 'asc'
      }
      if (sortEl.sort === 'desc') {
        toset = event.shiftKey ? '' : 'asc'
      }
      if (sortEl.sort === 'asc') {
        toset = 'desc'
      }
      sortEl.sort = toset
    }
  }
}



  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

td.active{
     background:#DC3545 !important;
     color:white;
     font-weight:bold !important;
}
table.month_menu td:hover{
     cursor:pointer;
}
ul.dept_menu li:hover{
     cursor:pointer;
}
ul.dept_menu li.active{
     font-weight:bold;
     color:white;
     background:#DC3545;
     padding:3px;
}
.hidden{
     display:none !important;
}

</style>
